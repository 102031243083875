<template>
    <b-nav-item
      :href="disabled ? `/welcome` : link">
      <div
        :id="`card-${bg}`"
        :class="[{ expanded: showMore }, 'tile-card']"
        :style="`background-color:${bg}`"
      >
        <span class="title">
          {{ title }}
          <lock-icon
            v-if="disabled"
            fillColor="#3687ff"
            class="lock-icon"
            :size="20"
          />
        </span>
        <div class="text">
            {{ text }}
        </div>
        <img :src="imgUrl" />
      </div>
      <b-popover v-if="disabled" :target="`card-${bg}`" triggers="hover" placment="auto" customClass="p-1">
        <div className="d-flex flex-column" >
          {{$t("welcome.schedulePopover")}}
          <b-nav-item
            link-classes="px-0 py-3"
            href="https://meetings-eu1.hubspot.com/veronika-vladova?__hstc=227324738.b6496e57860cdd223eef880b30fa5a9f.1720695982202.1720695982202.1720695982202.1&__hssc=227324738.2.1720695982202&__hsfp=3708446789&uuid=cd28b461-0e4a-4b4d-8458-90b68c511ebc">
              <b-button class="btn-link">
                {{$t("welcome.schedulePopoverLink")}}
              </b-button>
          </b-nav-item>
        </div>
      </b-popover>
    </b-nav-item>
  </template>
  
  <script>
  import LockIcon from "vue-material-design-icons/LockCheckOutline.vue";
  import { mapGetters } from "vuex";

  export default {
    name: "tile",
    props: {
      title: String,
      text: String,
      imgUrl: String,
      bg: String,
      length: Number,
      link: String,
      role: String
    },
    components: {
      LockIcon
    },
    data() {
      return {
        showMore: false,
        longText: this.text.length > this.length,
      };
    },
    computed: {
      ...mapGetters({ user: "getUser" }),
      disabled() {
        return this.role ? !this.user.permissions.includes(this.role) : false;
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .tile-card {
    border-radius: 15px;
    padding: toRem(16);
    display: flex;
    flex-flow: column;
    margin-bottom: toRem(16);
    &.expanded {
      height: fit-content;
      img {
        display: none;
      }
    }
    .title {
      font-size: toRem(16);
      font-weight: 600;
      display: block;
      text-align: center;
    }
    .text {
      padding: toRem(12) 0;
      p {
        font-size: toRem(14);
      }
    }
    img {
      margin: toRem(16) auto;
      margin-top: auto;
      display: block;
      height: 30%;
    }
    .btn-link {
      color: #4f4f4f;
      font-weight: 600;
      padding-left: 0;
      &:hover,
      &:focus {
        text-decoration: none;
        font-weight: bolder;
        color: #000;
      }
    }
  }
.nav-item {
  list-style: none;
}
  </style>
  